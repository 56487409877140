<template>
  <div
    class="Thumbnail Thumbnail-Activity"
    @mouseenter="setIdThumbnail(hit.objectID)"
    @mouseleave="setIdThumbnail(0)"
  >
    <a
      :href="isWidgetIframe ? utmUrl : url"
      :target="isWidget ? '_blank' : '_self'"
      :data-ux="dataUx"
      @click.prevent="trackCall"
    >
      <figure class="Thumbnail--Image">
        <template v-if="image">
          <img
            :src="
              resizedSrc(image, {
                width: 376,
                height: 212
              })
            "
            :alt="alt"
            onerror="this.style.display='none';"
          />
          <span
            v-if="!hasImageDefined"
            class="Thumbnail--ImageOverlay"
          />
        </template>
        <span
          v-else
          class="Thumbnail--ImagePattern"
        />
        <span
          v-if="hasMinPrice"
          class="Thumbnail--ImagePill"
        >
          {{ minPriceLabel }}
        </span>
      </figure>
      <div class="Thumbnail--Content">
        <div class="ContentWrapper">
          <span
            v-if="hasDate"
            class="Thumbnail--Date"
          >
            {{ getDateLabel(hit.start_date, hit.end_date) }}
          </span>
          <p class="Thumbnail--Name">
            {{ hit.title || hit.name }}
          </p>
          <p class="Thumbnail--Organization">
            <span class="Thumbnail--OrganizationBy">
              {{ $t('thumbnail.by') }}
            </span>
            <span class="Thumbnail--OrganizationName">
              {{ hit.organization_name || hit.name || 'N/A' }}
            </span>
          </p>
        </div>
        <div
          v-if="hasTagsOrLocation"
          class="ContentWrapper"
        >
          <div
            :class="[
              'Thumbnail--Metadata',
              { 'Thumbnail--Metadata-Split': hasTags }
            ]"
          >
            <span
              v-if="hasTags"
              class="Thumbnail--MetadataTag"
            >
              {{ hit.category_tags[0] }}
            </span>
            <span
              v-if="hit.place_city"
              class="Thumbnail--MetadataLocation"
            >
              <HaIcon :icon="faLocationDot" />
              {{ hit.place_city }}
              <template v-if="hit.place_zipcode">
                ({{ hit.place_zipcode }})
              </template>
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { functions, haMoment } from '@ha/helpers'
import { resizedSrc, amountToLocaleFormattedEuros } from '@/helpers/utils'
import {
  cardOrigin,
  buildCardInfo
} from '@/helpers'
import { useTracking, HaIcon } from '@ha/components-v3'
import useIsWidget from '@/composables/useIsWidget'
import { useI18n } from '#imports'

// TODO cleanup onerror everywhere
export default {
  name: 'ThumbnailActivity',
  components: { HaIcon },
  props: {
    hit: {
      type: [Array, Object],
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    /**
     * Used to build data-ux id tracking of thumbnails
     */
    idCarousel: {
      type: String,
      // required: true,
      default: ''
    },
    isMapOrigin: {
      type: Boolean,
      default: false
    },
    contextFrom: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { tracking } = useTracking()
    const { isWidget, isWidgetIframe } = useIsWidget()

    const i18n = useI18n()

    return {
      tracking,
      isWidget,
      isWidgetIframe,
      resizedSrc,
      faLocationDot,
      i18n
    }
  },
  data() {
    return {
      get
    }
  },
  computed: {
    image() {
      if (this.hit?.logo) {
        return this.hit?.logo
      }
      if (this.hit?.banner) {
        return this.hit?.banner
      }
      if (this.hasActivityTypes) {
        // custom activity types are not supported
        try {
          const suffix = this.formatActivityType(
            this.hit.activity_type
          )
          return `/_ex/placeholders/thumbnail/activity-${suffix}.jpg`
        } catch {
          return this.placeholderFallback
        }
      }
      return this.placeholderFallback
    },
    alt() {
      return this.$t('thumbnail.activityImageAlternative', [
        this.hit.name,
        this.hit.organization_name
      ])
    },
    placeholderFallback() {
      return `/_ex/placeholders/thumbnail/activity-default-${Math.floor(Math.random() * 4) + 1}.jpg`
    },
    hasImageDefined() {
      return !!get(this.hit, 'logo') || !!get(this.hit, 'banner')
    },
    hasMinPrice() {
      return get(this.hit, 'min_price') !== undefined
    },
    hasMaxPrice() {
      return get(this.hit, 'max_price') !== undefined
    },
    minPriceLabel() {
      return this.hasMaxPrice && get(this.hit, 'max_price') === 0
        ? this.$t('thumbnail.free')
        : this.$t('thumbnail.startingAt', [
            amountToLocaleFormattedEuros({
              amount: this.hit?.min_price,
              areCents: true
            })
          ])
    },
    hasDate() {
      return (
        !!get(this.hit, 'start_date') || !!get(this.hit, 'end_date')
      )
    },
    hasActivityTypes() {
      return get(this.hit, 'activity_type.length', 0) > 0
    },
    hasTags() {
      return get(this.hit, 'category_tags.length', 0) > 0
    },
    hasTagsOrLocation() {
      return (
        !!get(this.hit, 'category_tags') ||
        !!get(this.hit, 'place_city')
      )
    },
    dataUx() {
      const page = this.$route.path.slice(2, 12)
      const slug = page === '/recherche' ? 'Search' : 'LP'

      let dataUx = `Explore_${slug}_ThumbnailActivity_`

      if (this.idCarousel !== '') {
        dataUx += `${this.idCarousel}_`
      }
      return dataUx + this.index
    },
    url() {
      return this.isWidgetIframe
        ? this.hit.url
        : this.hit.url
            .replace('https://', '')
            .replace('www.', '')
            .replace('helloasso-dev.com', '')
            .replace('helloasso.com', '')
    },
    utmUrl() {
      return `${this.url}?utm_source=partenaire&utm_medium=referral&utm_campaign=widget&utm_content=campagne`
    }
  },
  methods: {
    hasValidActivityType(type) {
      return !!type && type !== 'Non renseigné'
    },

    /**
     * @param {Number} timeStamp1
     * @param {Number} timeStamp2
     * @returns {String} label with data | date range to display
     * @error No startDate will result in Invalid Date
     */

    getDateLabel(startDate, endDate) {
      // If no date is entered no message is displayed
      if (!startDate && !endDate) {
        return null
      }

      startDate = haMoment(startDate)

      if (!endDate) {
        return this.$t('date.onlyBeginDate', [
          startDate.format('DD/MM/YYYY')
        ])
      }

      endDate = haMoment(endDate)

      // both dates defined and not the same day
      if (startDate.isSame(endDate, 'day')) {
        return this.$t('date.SameDay.withNoTime', [
          endDate.format('DD/MM/YYYY')
        ])
      }

      return this.$t('date.with2Dates.withNoTime', [
        startDate.format('DD/MM/YYYY'),
        endDate.format('DD/MM/YYYY')
      ])
    },
    // Formatting needed while types are reworked
    // It will be far less overkill
    formatActivityType(activityType) {
      activityType = functions.deburr(
        activityType.split(' ').join('-').toLowerCase()
      )
      return activityType
    },
    setIdThumbnail(id) {
      this.$store.commit('organizations/SET_THUMBNAIL', id)
    },
    trackCall(e) {
      const { href, target } = e.target.closest('a')

      const origin = cardOrigin(
        this.isMapOrigin,
        this.$store.getters['filters/getTab'],
        this.contextFrom
      )
      const trackInfo = buildCardInfo(this.hit, this.index, origin)

      this.tracking?.track('Explore Card Clicked', trackInfo)
      window.open(href, target, 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/thumbnail-activity';

#home .Thumbnail-Activity {
  &,
  .Thumbnail--Image {
    height: auto;
  }

  > a {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--hads-color-silver);
    border-radius: var(--hads-radius-large);
    overflow: hidden;

    &:hover {
      .Thumbnail--Name {
        color: var(--hads-color-iris);
      }

      .Thumbnail--Image img {
        transform: scale(1.1);
      }
    }
  }

  .Thumbnail--Image {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      transform: scale(
        1.025
      ); // Edge render engine jiggling on our tiles without this line.

      transition: transform var(--hads-transition-simple);
      object-fit: cover;
      aspect-ratio: var(--hads-ratio-16-9);
    }
  }

  .Thumbnail--ImagePill {
    position: absolute;
    background: var(--hads-color-white);
    padding: var(--hads-spacing-xxsmall) var(--hads-spacing-xsmall);
    border-radius: var(--hads-radius-medium);
    box-shadow: var(--hads-box-shadow);
    font-weight: 600;
    color: var(--hads-color-storm);
  }

  .Thumbnail--Date {
    position: absolute;
    top: -1.5em;
    right: 1ch;
    font-size: 1em;
    max-width: 80%;
  }

  .Thumbnail--Content {
    padding: var(--hads-spacing-small);
    position: relative;
    height: 9rem;
    display: flex;
    flex-direction: column;
  }

  .Thumbnail--Name {
    font-weight: 600;
    color: var(--hads-color-storm);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .Thumbnail--Organization {
    font-size: 0.9em;
    color: var(--hads-color-storm);
    display: flex;
    gap: 0.25em;
  }

  .Thumbnail--OrganizationName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Thumbnail--Metadata {
    font-size: 0.8em;
    display: flex;
    gap: var(--hads-spacing-medium);
    justify-content: space-between;
    align-items: center;

    > :only-child {
      margin-left: auto;
    }

    > * {
      display: inline-block;
    }

    .Thumbnail--MetadataTag {
      background: var(--hads-color-iris-shade-5);
      padding: var(--hads-spacing-xxsmall) var(--hads-spacing-xsmall);
      border-radius: var(--hads-radius-roundest);
      font-weight: 600;
      color: var(--hads-color-iris);
      white-space: nowrap;
    }

    .Thumbnail--MetadataLocation {
      color: var(--hads-color-storm);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
